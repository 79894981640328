import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import React from 'react'
import { graphql } from 'gatsby'
import classnames from "classnames";
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import styled, { css } from 'styled-components'

import EmailCtaSection from '../components/email-signup/email-cta-section'


class TipTemplate extends React.Component {

  render() {
    // Gets the content from the GraphQL query
    const post = get(this.props, 'data.contentfulTip')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    console.log(post.content.json)
    // Takes the post content in Json and uses documentToReactComponents to turn it into usable HTML content.
    const postContent = documentToReactComponents(post.content.json)

    // Layout for the Tip template
    return (
      <Layout location={this.props.location} >

        <Background>

          <Navigation />

          <Helmet title={post.title} />

          <ArticleColumn className="container">
            <ArticleBackground background={post.backgroundColour}>
              <div className="container boxed-width justify-content-center is-direction-column">
              <InnerArticleBackground className="col-6 col-12-t">

                <ArticleTypeWrapper>
                  <ArticleType className={post.type.toString().toLowerCase().replace(/\s/g, "-")}>
                    {post.type}
                  </ArticleType>
                </ArticleTypeWrapper>

                <BlogTitle>
                  {post.title}
                </BlogTitle>
                <PreviewParagraph className="tip-overview"
                  dangerouslySetInnerHTML={{
                    __html: post.teaserText,
                  }}
                />
              </InnerArticleBackground>

              <PostImageSection>
                <TipImage>
                  <Img className="post-image" fluid={post.image.fluid} imgStyle={{ objectFit: "contain"}} />
                </TipImage>

              </PostImageSection>
              </div>
            </ArticleBackground>
          </ArticleColumn>
          <PostContainer className="container boxed-width justify-content-center is-direction-column">
            <PostWrapper className="col-6 col-12-t">
              <PostBody className="post-body">

                { postContent ?
                  <div>
                    {postContent}
                  </div>
                :
                ''
                }

              </PostBody>

              <SubscribeText>
                <h2>Subscribe</h2>
                <p>If you found this tip useful, consider subscribing with the form at the bottom of this page. If you didn’t find this useful, let me know what I could have done better at <a href="mailto:callum@uiuxtips.com">callum@uiuxtips.com</a>.</p>
              </SubscribeText>

              <AuthorSection>
                <AuthorImage src="/content/callum.jpg" alt="Callum's profile picture" />
                <PostDate>Created by <span>Callum Harrod</span> on <span>{post.publishDate}</span></PostDate>
              </AuthorSection>

            </PostWrapper>
          </PostContainer>

          <div className="padding-top-large">
            <EmailCtaSection />
          </div>

        </Background>
      </Layout>
    )
  }
}

export default TipTemplate

// GraphQL Query
export const pageQuery = graphql`
  query TipsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTip(slug: { eq: $slug }) {
      title
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      type
      image {
        fluid(quality: 100, maxWidth: 2400) {
          src
          tracedSVG
          aspectRatio
          srcSet
          sizes
        }
      }
      teaserText
      content {
        json
      }
    }
  }
`

// Styles

const PostContainer = styled.div `
  position: relative;

  @media (max-width: 768px) {
  }
`

const PostWrapper = styled.div `
  position: relative;
  z-index: 1;
`

const EmojiImgContainer = styled.div `
  max-width: 48px;
  height: 48px;
  width: 100%;
  margin-bottom: 12px;
  margin-top: auto;
  align-self: center;

  .gatsby-image-wrapper, img, picture, picture img {
    height: 42px;
    width: 100%;
    object-fit: contain;
  }
`

const EmojiImg = styled.div `
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  img {
    max-width: 42px;
    height: auto;
    max-height: 42px;
    object-fit: contain;
  }
`

const PostTitle = styled.h1 `
  font-size: 40px;
  line-height: 50px;
  color: #0B072F;
`

const PostBody = styled.div `
  display: block;

  h1, h2, h3, h4, h5, h6, p, a, img {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    display: inline-flex;
    color: #fff;
  }

  h3 {
    font-size: 20px;
  }

  h3, h4, h5, h6 {
    margin-top: 0;
  }

  p, ul, li {
    font-size: 16px;
    line-height: 2;
    color: #9C9C9C;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 48px;

    li {
      margin-bottom: 12px;
    }
  }

  a {
    color: #FFF;
    text-decoration: underline;
  }

  img {
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    
  }
`

// Article preview

const ArticleColumn = styled.div `
  width: 100%;
  display: flex;
`

const ArticleBackground = styled.div`
  display: flex;
  align-items: flex-start;
  color: #1a1a1a;
  text-decoration: none;
  position: relative;
  height: 100%;
  width: 100%;
`

const InnerArticleBackground = styled.div `
  display: flex;
  padding: 96px 0px 24px;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const BlogTitle = styled.h1`
  line-height: 1.5;
  margin-bottom: 12px;
  margin-top: 0;
  display: block;
`

const PreviewParagraph = styled.p `
  color: #9C9C9C;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
`

const PreHeader = styled.div `
  display: flex;
  flex-direction: column;
`

const ArticleTypeWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`

const ArticleType = styled.span `
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  margin-bottom: 0;
  color: #9C9C9C;
`

// Post image section
const PostImageSection = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px 0 48px;
  flex-wrap: wrap;
  align-items: flex-start;
`

// Tip Image
const TipImage = styled.div `
  order: 1;
  width: 100%;

  @media (max-width: 768px) {
    order: 1;
    margin: 0;
  }
`

// Good and bad
const WhatsBad = styled.div `
  order: 2;
  width: calc(50% - 12px);
  margin-right: 24px;
  color: #fff;
  display: flex;
  justify-content: center;

  ul, li {
   color: #fff;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`
const WhatsGood = styled.div `
  order: 3;
  width: calc(50% - 12px);
  margin-left: auto;
  color: #fff;
  display: flex;
  justify-content: center;

  ul, li {
    color: #fff;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

`

const Background = styled.div `
`


const SubscribeText = styled.div `
  padding-top: 48px;

  h2 {
    color: #ffd265;
  }

  p {
    margin-bottom: 0;
  }
`

const AuthorSection = styled.div `
  padding-top: 48px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
`

const AuthorImage = styled.img `
  border-radius: 50%;
  max-width: 72px;
`

const PostDate = styled.p `
  margin-left: 24px;
  margin-bottom: 0;

  span {
    color: #fff;
  }
`